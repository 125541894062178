// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-css-modules-js": () => import("./../../../src/pages/about-css-modules.js" /* webpackChunkName: "component---src-pages-about-css-modules-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-banquets-events-js": () => import("./../../../src/pages/banquets-events.js" /* webpackChunkName: "component---src-pages-banquets-events-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-graduation-portraits-js": () => import("./../../../src/pages/graduation-portraits.js" /* webpackChunkName: "component---src-pages-graduation-portraits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portraits-js": () => import("./../../../src/pages/portraits.js" /* webpackChunkName: "component---src-pages-portraits-js" */),
  "component---src-pages-weddings-engagements-js": () => import("./../../../src/pages/weddings-engagements.js" /* webpackChunkName: "component---src-pages-weddings-engagements-js" */)
}

